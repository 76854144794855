import React from 'react';

function DesignPattern(){
    const personal_info = [
        {storeName: "Smilepay",titleSlogan: "pay with smile"},
    ]
    return(
        <div className='drip_title_box_container'>
            <div className='drip_title_box'>
            <img src='/user/15044/title.jpg' className="img-fluid" alt="Responsive image"></img>
            <h3 className='font-weight-bold drip_title_text'>紙筆 靈感的起點</h3>
            </div>
        </div>
        
    )
}
export default DesignPattern;