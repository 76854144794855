import React from 'react';
import '../style/success.css';

function SuccessMark(){
    return(
        <div className='success_checkmark'>
        <div className="check_icon">
            <span className = 'icon_line line_tip' />
            <span className = 'icon_line line_long' />
            <div className = 'icon_circle' />
            <div className = 'icon_fix' />
        </div>
    </div>
    );
}

export default SuccessMark;
