import React from 'react';
import '../style/ShrinkBox.css';

function ShrinkBox({ isActive, onClick }) {
  return (
    <div
      className={`menu_icon_block ${isActive ? 'active' : ''}`}
      id="menu_icon_block"
      onClick={onClick} // 點擊時調用父組件傳遞的函數
    >
      <div id="menu_icon_wrapper" className="menu_icon_wrapper">
        <div className="menu_icon_line half first"> </div>
        <div className="menu_icon_line"></div>
        <div className="menu_icon_line half last"></div>
      </div>
    </div>
  );
}

export default ShrinkBox;
