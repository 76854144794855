import React, { useState, useRef, useEffect } from 'react';
import CrossMark from './CrossMark';
import AlertMessage from './AlertMessage';  // 引入 Mask 組件
import Checkout from './Checkout';
import '../style/Cart.css';
function Cart({ handleCloseCart, os, products, goCheckout, setGoCheckout}) {
  const divRef = useRef(null);
  const [cartItems, setCartItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [triggerKey, setTriggerKey] = useState(0);
  const [executionSignal, setExecutionSignal] = useState('');

  
  const shouldShowCrossMark = Array.isArray(os) && (os.includes('Android') || os.includes('Phone'));

  // 當購物車組件加載時，從 localStorage 中讀取數據
  useEffect(() => {
    const savedCartItems = getCartFromLocalStorage();
    setCartItems(savedCartItems);
  }, []);

  // 處理數量增加的函數
  const handleIncreaseQuantity = (index) => {
    console.log(products)
    const newCartItems = [...cartItems];
    const cartItem = newCartItems[index];
    console.log(cartItem)
    const product = products.find(item => item.id === cartItem.id);
  
    if (product && product.reserve > cartItem.quantity) {
      // 如果庫存足夠，才增加購物車中的數量
      newCartItems[index].quantity += 1;
      setCartItems(newCartItems);
      setCartInLocalStorage(newCartItems);  // 更新 localStorage
    } else {
      setAlertMessage(`${cartItem.title} 庫存不足`);
      setExecutionSignal('fail');
      setTriggerKey(prev => prev + 1); // 觸發提示
    }
  };

  // 處理數量減少的函數
  const handleDecreaseQuantity = (index) => {
    console.log(index)
    const newCartItems = [...cartItems];
    if (newCartItems[index].quantity > 1) {
      newCartItems[index].quantity -= 1;
    } else {
      newCartItems.splice(index, 1);  // 如果數量為 1，則移除該項目
    }
    setCartItems(newCartItems);
    setCartInLocalStorage(newCartItems);  // 更新 localStorage
  };

  // 處理移除的函數
  const handleDelete = (index) => {
    const newCartItems = [...cartItems];
    newCartItems.splice(index, 1);  // 移除該項目

    if (newCartItems.length === 0) {
      // 如果沒有剩餘的項目，則移除 localStorage
      removeCartFromLocalStorage();
    } else {
      // 如果還有剩餘的項目，更新 localStorage
      setCartInLocalStorage(newCartItems);
    }

    setCartItems(newCartItems);
  };

  // 移除 localStorage 的函數
  const removeCartFromLocalStorage = () => {
    localStorage.removeItem('dripCartItems');  // 移除 localStorage 的數據
  };

  // 將購物車數據保存到 localStorage
  const setCartInLocalStorage = (cartItems) => {
    localStorage.setItem('dripCartItems', JSON.stringify(cartItems));
  };

  // 從 localStorage 中讀取購物車數據
  const getCartFromLocalStorage = () => {
    const cartString = localStorage.getItem('dripCartItems');
    return cartString ? JSON.parse(cartString) : [];
  };

  return (
    <div ref={divRef} className={`cart_list list_show ${goCheckout ? 'active' : ''}`}>
      {shouldShowCrossMark && <CrossMark handleClose={handleCloseCart} />}
      <h2 className='drip_cart_title'>購物車</h2>

      {cartItems.length === 0 ? (
        <div className='drip_cart_title'>購物車是空的</div>
      ) : (
        <div>
          {goCheckout ?  
          ( !shouldShowCrossMark && <div className='drip_backToShoping'  onClick={handleCloseCart}>繼續購物</div>) :
         
          (<div className='drip_go_checkout'  onClick={() => setGoCheckout(true)}>前往結帳</div>)
          
          }
         
          {cartItems.map((item, index) => (
            <div key={index} className='d-flex flex-wrap flex-row bg-light mb-3 justify-content-between align-items-center'>
                <div className="drip_cart_container_1">
                    <div className='drip_cart_img_container'>
                        <img className="card-img-top" src={`/user/15044/${item.id}.jpg`} alt={item.title} />
                    </div>
                    <div  className='drip_cart_product_info_container'>
                        <div>{item.title}</div>
                        <div className='drip_cart_product_price_container'>NT${item.price}</div>
                    </div>
                </div>
              <div className="drip_cart_container_2">
                <div className="drip_cart_number_container">
                    <div className="" onClick={() => handleDecreaseQuantity(index)}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Edit / Remove_Minus">
                            <path d="M6 12H18" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </div>

                    <input type="text" className="form-control text-center" value={item.quantity} readOnly/>

                    <div className="" onClick={() => handleIncreaseQuantity(index)}>
                   
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Edit / Add_Plus">
                            <path d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='drip_cart_delete_button' onClick={() => handleDelete(index)}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 6.38597C3 5.90152 3.34538 5.50879 3.77143 5.50879L6.43567 5.50832C6.96502 5.49306 7.43202 5.11033 7.61214 4.54412C7.61688 4.52923 7.62232 4.51087 7.64185 4.44424L7.75665 4.05256C7.8269 3.81241 7.8881 3.60318 7.97375 3.41617C8.31209 2.67736 8.93808 2.16432 9.66147 2.03297C9.84457 1.99972 10.0385 1.99986 10.2611 2.00002H13.7391C13.9617 1.99986 14.1556 1.99972 14.3387 2.03297C15.0621 2.16432 15.6881 2.67736 16.0264 3.41617C16.1121 3.60318 16.1733 3.81241 16.2435 4.05256L16.3583 4.44424C16.3778 4.51087 16.3833 4.52923 16.388 4.54412C16.5682 5.11033 17.1278 5.49353 17.6571 5.50879H20.2286C20.6546 5.50879 21 5.90152 21 6.38597C21 6.87043 20.6546 7.26316 20.2286 7.26316H3.77143C3.34538 7.26316 3 6.87043 3 6.38597Z" fill="#1C274C"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.42543 11.4815C9.83759 11.4381 10.2051 11.7547 10.2463 12.1885L10.7463 17.4517C10.7875 17.8855 10.4868 18.2724 10.0747 18.3158C9.66253 18.3592 9.29499 18.0426 9.25378 17.6088L8.75378 12.3456C8.71256 11.9118 9.01327 11.5249 9.42543 11.4815Z" fill="#1C274C"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.5747 11.4815C14.9868 11.5249 15.2875 11.9118 15.2463 12.3456L14.7463 17.6088C14.7051 18.0426 14.3376 18.3592 13.9254 18.3158C13.5133 18.2724 13.2126 17.8855 13.2538 17.4517L13.7538 12.1885C13.795 11.7547 14.1625 11.4381 14.5747 11.4815Z" fill="#1C274C"/>
                  <path opacity="0.5" d="M11.5956 22.0001H12.4044C15.1871 22.0001 16.5785 22.0001 17.4831 21.1142C18.3878 20.2283 18.4803 18.7751 18.6654 15.8686L18.9321 11.6807C19.0326 10.1037 19.0828 9.31524 18.6289 8.81558C18.1751 8.31592 17.4087 8.31592 15.876 8.31592H8.12405C6.59127 8.31592 5.82488 8.31592 5.37105 8.81558C4.91722 9.31524 4.96744 10.1037 5.06788 11.6807L5.33459 15.8686C5.5197 18.7751 5.61225 20.2283 6.51689 21.1142C7.42153 22.0001 8.81289 22.0001 11.5956 22.0001Z" fill="#1C274C"/>
                </svg>
              </div>
              </div>
              
              <hr className='hr_style' />
            </div>
          ))}
        </div>
      )}
      {goCheckout && (<Checkout os={os}/>)}
 
    <AlertMessage message={alertMessage} triggerKey={triggerKey} executionSignal={executionSignal} />
    </div>
  );
}

export default Cart;
