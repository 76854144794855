import React from 'react'; 
import '../style/Logistics.css';

function Logistics({ logisticsType }) {
  console.log(logisticsType);

  return (
    <div className='checkoutChooseInfo'>
      {/* 黑貓宅急便及貨到付款 */}
      {logisticsType === "Tcat" || logisticsType === "TcatToPay" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
    
            <div>
              <div>黑貓</div>
              <hr/>
            </div>

            <div className='checkout_list_container'>
              <div className='checkout_list_title'>姓名</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>手機號碼</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>市話</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>地址</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>

            <div>
              <div>收件人</div>
              <hr/>
            </div>

            <div className='checkout_list_container'>
              <div className='checkout_list_title'>姓名</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>手機號碼</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>市話</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>地址</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>

            <div>
              <div>發票資料</div>
           <hr/>
            </div>
      
        </div>
      ) : null}

      {/* 7-11 */}
      {logisticsType === "7_11" || logisticsType === "7_11ToPay" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
   
            <div>
              <div>7-11</div>
              <hr/>
            </div>

            <div className='checkout_list_container'>
              <div className='checkout_list_title'>姓名</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>手機號碼</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>市話</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>地址</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>

        </div>
      ) : null}

      {/* 全家 */}
      {logisticsType === "family" || logisticsType === "familyToPay" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
      
            <div>
              <div>全家</div>
              <hr/>
            </div>

            <div className='checkout_list_container'>
              <div className='checkout_list_title'>姓名</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>手機號碼</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>市話</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>地址</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>

        </div>
      ) : null}
    </div>
  );
}

export default Logistics;
