import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import SuccessMark from './SuccessMark';
import FailMark from './FailMark';
import '../style/success.css';

const AlertMessage = ({ message, triggerKey, executionSignal }) => {
    const duration = 1500; // 控制顯示的時長
    const [visible, setVisible] = useState(false);
    const [messageHide, setMessageHide] = useState(false); // 控制 messageHide 動畫的狀態
    const [isPaused, setIsPaused] = useState(false); // 控制是否暫停隱藏
    const alertRef = useRef(null); // 用來參考 alert DOM 元素

    useEffect(() => {
        if (message) {
            setVisible(true);
            setMessageHide(false); // 確保動畫開始時不會觸發 messageHide

            if (!isPaused) {
                const timer = setTimeout(() => {
                    setMessageHide(true); // 觸發 messageHide
                }, duration - 300);

                return () => clearTimeout(timer);
            }
        }
    }, [triggerKey, isPaused]); // 依賴 triggerKey 和 isPaused

    useEffect(() => {
        if (messageHide && !isPaused) {
            const timer = setTimeout(() => {
                setVisible(false);
            }, 300); // 動畫時長為 0.3s

            return () => clearTimeout(timer);
        }
    }, [messageHide, isPaused]);

    // 處理滑鼠進入
    const handleMouseEnter = () => {
        setIsPaused(true); // 當滑鼠進入，暫停隱藏
    };

    // 處理滑鼠離開
    const handleMouseLeave = () => {
        setIsPaused(false); // 滑鼠離開，恢復隱藏
    };

    // 處理點擊頁面其他地方加快隱藏的邏輯
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (alertRef.current && !alertRef.current.contains(event.target)) {
                setMessageHide(true); // 點擊其他地方時立即觸發消失動畫
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!visible) return null;

    return ReactDOM.createPortal(
        <div
            ref={alertRef} // 參考 DOM 元素
            className={`alert-message ${messageHide ? 'fadeOut' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {executionSignal === 'success' ? <SuccessMark /> : <FailMark />}
            {message}
        </div>,
        document.body
    );
};

export default AlertMessage;
