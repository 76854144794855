  import React, { useState, useEffect } from 'react';
  import AlertMessage from './AlertMessage';  // 引入 Mask 組件
  import ProductDetail from './ProductDetail'; // 引入 ProductDetail 組件
  import '../style/ProductBox.css';
  let timeoutId;
  
  function ProductBox({ addToCart, setCartActive, products }) {
    const [alertMessage, setAlertMessage] = useState('');
    const [triggerKey, setTriggerKey] = useState(0);
    const [executionSignal, setExecutionSignal] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null); // 用來儲存選中的商品數據
    const [cartProductNum, setCartProductNum] = useState(0); // 選中的商品當前在扣物車內的數量
    const [selectedProductIndex, setSelectedProductIndex] = useState(null); // 用來儲存選中的商品數據
    const [isInsufficientQuantity, setIsInsufficientQuantity] = useState(false); // 用來判斷 購物車內數量 狀態



/*
      // 使用 useEffect 在組件初始化時根據購物車中的數量來更新庫存
  useEffect(() => {
    const storedCart = getCartFromLocalStorage();  // 從 localStorage 讀取購物車數據
    if (storedCart.length > 0) {
      // 根據購物車中的商品，更新 products 的庫存
      const updatedProducts = products.map(product => {
        const cartItem = storedCart.find(item => item.id === product.id);
        if (cartItem) {
          // 如果購物車中有該商品，從庫存中扣除購買的數量
          return {
            ...product,
            reserve: (Number(product.reserve) - cartItem.quantity).toString(),
          };
        }
        return product;
      });
      setProducts(updatedProducts); // 更新 products 狀態
      console.log(products)
    }
  }, []);
  */
    const handleAddToCart = (item, thisIndex) => {
      // 讀取現有的購物車數據
      const currentCart = getCartFromLocalStorage();
  
      // 檢查商品是否已經存在於購物車中
      const existingItem = currentCart.find(cartItem => cartItem.id === item.id);
      const reserveNum = Number(products[thisIndex].reserve);

  
      if (existingItem) {
        // 如果商品已經存在
        if (existingItem.quantity+1 <= reserveNum) {
          existingItem.quantity += 1;
          //products[thisIndex].reserve = (reserveNum - 1).toString(); // 更新庫存數量
          console.log(products)
        } else {
          setAlertMessage(`${item.title} 庫存不足`);
          setExecutionSignal('fail');
          setTriggerKey(prev => prev + 1); // 觸發提示
          return;
        }
      } else {
        // 如果商品不存在
        if (reserveNum !== 0) {
          item.quantity = 1;
          //products[thisIndex].reserve = (reserveNum - 1).toString(); // 庫存減少 1
          currentCart.push(item);
          console.log(products)
        } else {
          setAlertMessage(`${item.title} 庫存不足`);
          setExecutionSignal('fail');
          setTriggerKey(prev => prev + 1); // 觸發提示
          return;
        }
      }
  
      // 將更新後的購物車保存到 localStorage
      setCartInLocalStorage(currentCart);
  
      // 更新 products state 中的庫存
 /*
      setProducts(prevProducts =>
        prevProducts.map(prod =>
          prod.id === item.id ? { ...prod, reserve: item.reserve } : prod
        )
      );
  */
      // 確認商品是否存在於 localStorage
      const storedCart = getCartFromLocalStorage();
      const isItemInCart = storedCart.some(cartItem => cartItem.id === item.id);
  
      if (isItemInCart) {
        if (document.getElementById('drip_cart').classList.contains('drip_active')) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          setCartActive(false);
        }
  
        setAlertMessage(`${item.title} 已成功加入購物車`);
        setExecutionSignal('success');
        setTriggerKey(prev => prev + 1); // 觸發提示
        addToCart(item);
        setCartActive(true);
  
        // 設置新的 timeout
        timeoutId = setTimeout(() => {
          setCartActive(false);
        }, 500);
      } else {
        setAlertMessage(`${item.title} 添加購物車失敗`);
        setExecutionSignal('fail');
        setTriggerKey(prev => prev + 1);
      }
    };
  
    const handleAddToCartFromDetail = (product, quantity, thisIndex) => {
      // 根據數量 quantity 將產品添加到購物車
      const currentCart = getCartFromLocalStorage();
      const existingItem = currentCart.find(cartItem => cartItem.id === product.id);
      const reserveNum = Number(products[thisIndex].reserve);

  console.log(quantity)
  console.log(reserveNum)
      if (existingItem) {
        if (existingItem.quantity + quantity <= reserveNum) {
          existingItem.quantity += quantity;
          //products[thisIndex].reserve = (reserveNum - quantity).toString();
          console.log(products)
        } else {
          setAlertMessage(`${product.title} 庫存不足`);
          setExecutionSignal('fail');
          setTriggerKey(prev => prev + 1);
          return;
        }
      } else {
        if (reserveNum >= quantity) {
          product.quantity = quantity;
          //products[thisIndex].reserve = (reserveNum - quantity).toString();
          currentCart.push(product);
          console.log(products)
        } else {
          setAlertMessage(`${product.title} 庫存不足`);
          setExecutionSignal('fail');
          setTriggerKey(prev => prev + 1);
          return;
        }
      }
  
      setCartInLocalStorage(currentCart);
/*
      setProducts(prevProducts =>
        prevProducts.map(prod =>
          prod.id === product.id ? { ...prod, reserve: product.reserve } : prod
        )
      );
*/
      setAlertMessage(`${product.title} 已成功加入購物車`);
      setExecutionSignal('success');
      setTriggerKey(prev => prev + 1);
      addToCart(product);
      setCartActive(true);
  
      timeoutId = setTimeout(() => {
        setCartActive(false);
      }, 500);
    };

    const setCartInLocalStorage = (cartItems) => {
      const cartString = JSON.stringify(cartItems);
      localStorage.setItem('dripCartItems', cartString);
    };
  
    const getCartFromLocalStorage = () => {
      const cartString = localStorage.getItem('dripCartItems');
      return cartString ? JSON.parse(cartString) : [];
    };
  
    const handleProductClick = (product, index) => {
      // 設置選中的商品，打開商品詳情視窗
      setSelectedProduct(product);
      setSelectedProductIndex(index);
      const currentCart = getCartFromLocalStorage();
      const existingItem = currentCart.find(cartItem => cartItem.id === product.id);
      if (existingItem) {
        setCartProductNum(existingItem.quantity)
        if(existingItem.quantity >= Number(product.reserve)){
          setIsInsufficientQuantity(true)
        }else{
          setIsInsufficientQuantity(false)
        }
      }else{
        if(product.reserve=='0'){
          console.log('!')
          setIsInsufficientQuantity(true)
        }else{
          setIsInsufficientQuantity(false)
        }
      }

    };
  
    const handleCloseDetail = () => {
      // 關閉商品詳情視窗
      setSelectedProduct(null);
      setSelectedProductIndex(null);
    };
  
    return (
      <div className='product_area_container'>
        <AlertMessage message={alertMessage} triggerKey={triggerKey} executionSignal={executionSignal} />
        <div className='total_info_box'>
          <div>共 {products.length} 件商品</div>
        </div>
        <div className='product_area'>
          {products.map((element, index) => (
            <div key={index} className="card product_container" onClick={() => handleProductClick(element,index)}>
              <div className='drip_img_container'>
                {element.reserve == '0' && (<div className='soldOutMark'>已售完</div>)}
                <img className="drip_img" src={`/user/15044/${element.id}.jpg`} alt={element.title} />
              </div>
              <div className="card-body">
                <p className="card-title drip_card_title">{element.title}</p>
                <div className='drip_card_txt_box'>
                  <p className="drip_card_txt">NT$ {element.price}</p>
                  <p className="drip_card_txt">  {element.reserve == 'max' ? '-' : `數量：${element.reserve}`}</p>
                </div>
                {/* 有庫存才能出現按鈕 */}
                {element.reserve !== '0' && (
                   <button className="btn btn-primary" onClick={(event) => { event.stopPropagation(); handleAddToCart(element, index) }}>
                   加入購物車
                 </button>
                )}
               
              </div>
            </div>
          ))}
        </div>
          {/* 如果選擇了商品，顯示商品詳情 */}
          {selectedProduct && <ProductDetail product={selectedProduct} itemIndex={selectedProductIndex} onClose={handleCloseDetail}  handleAddToCartFromDetail={handleAddToCartFromDetail} cartProductNum={cartProductNum} isInsufficientQuantity={isInsufficientQuantity}/>}
      </div>
    );
  }
  
  export default ProductBox;