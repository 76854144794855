import React from 'react'; 
import '../style/CashFlow.css';

function CashFlow({ cashFlowType }) {
  console.log(cashFlowType);

  return (
    <div className='checkoutChooseInfo'>
      {/* 信用卡 */}
      {cashFlowType === "creditCard" || cashFlowType === "creditCard" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
          <div>
            <div>
              <div>信用卡付款</div>
              <hr/>
            </div>

            <div className='checkout_list_container'>
              <div className='checkout_list_title'>卡號</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>到期日</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>安全碼</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>
            <div className='checkout_list_container'>
              <div className='checkout_list_title'>姓名</div>
              <div className='checkout_list_input_box'>
                <input />
              </div>
            </div>

          </div>
        </div>
      ) : null}

      {/* 條碼繳費 */}
      {cashFlowType === "marketBarcode" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
          <div>
            <div>
              <div>超商條碼繳費</div>
              <hr/>
            </div>

   
          </div>
        </div>
      ) : null}

      {/* 全家取付 */}
      {cashFlowType === "family" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
          <div>
            <div>
              <div>全家取貨付款</div>
              <hr/>
            </div>

          </div>
        </div>
      ) : null}


      {/* 7-11取付 */}
      {cashFlowType === "7_11" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
          <div>
            <div>
              <div>7-11取貨付款</div>
              <hr/>
            </div>

          </div>
        </div>
      ) : null}


      {/* 黑貓取付 */}
      {cashFlowType === "Tcat" ? (
        <div className='logisticsTypeCheckoutBox fadeIn'>
          <div>
            <div>
              <div>黑貓取貨付款</div>
              <hr/>
            </div>

          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CashFlow;
