import React from 'react';

function SmilePayBottomBar(){
    const personal_info = [
        {storeName: "Smilepay",titleSlogan: "pay with smile"},
    ]
    return(
        <nav className="bg-secondary mw-100 w-auto p-3 d-flex flex-column align-items-center">
            <div className="d-flex flex-column bd-highlight mb-3">
                <p><strong>HSUN HANG TECHNOLOGY CO., LTD. technical support訊航科技 技術支援</strong></p>
            </div>
      </nav>
        
    )
}
export default SmilePayBottomBar;