import React, { useState, useEffect } from 'react';
import TopBar from '../component/TopBar';
import DesignPattern from '../component/DesignPattern';
import ProductBox from '../component/ProductBox';
import BottomBar from '../component/BottomBar';
import SmilePayBottomBar from '../component/SmilePayBottomBar';


var os = function () {
  var ua = navigator.userAgent,
      isWindowsPhone = /(?:Windows Phone)/.test(ua),
      isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua),
      isChrome = /(?:Chrome|CriOS)/.test(ua),
      isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
      isPhone = /(?:iPhone)/.test(ua) && !isTablet,
      isPc = !isPhone && !isAndroid && !isSymbian,
      isMac = /(?:Macintosh|Mac OS X)/.test(ua) && !isPhone && !isTablet;

  var deviceTypes = [];

  if (isTablet) deviceTypes.push('Tablet');
  if (isPhone) deviceTypes.push('Phone');
  if (isAndroid) deviceTypes.push('Android');
  if (isMac) deviceTypes.push('Mac');
  if (isPc) deviceTypes.push('PC');

  return deviceTypes.length > 0 ? deviceTypes : ['Unknown']; // 如果沒有符合條件，返回 'Unknown'
};


function Main(){
  const [cartItems, setCartItems] = useState([]); // 用來追蹤購物車中的商品
  const [cartActive, setCartActive] = useState(false); // 控制購物車按鈕的 active 狀態
  const [products, setProductList] = useState([]); // 控制購物車按鈕的 active 狀態
  useEffect(() => {
    setProductList([
    { title: '羊皮紙', id: '1', price: '100', reserve: '23',short_detial_info:'真實羊皮紙製作', detial_info: '尺寸 29*21 cm' },
    { title: '羽毛筆', id: '2', price: '220', reserve: '4',short_detial_info:'經典復刻羽毛筆' , detial_info: '長 20 cm' },
    { title: '橡皮擦', id: '3', price: '230', reserve: '0',short_detial_info:'超黏屑橡皮擦', detial_info: '尺寸  5*3 cm' },
    { title: '蠟筆', id: '4', price: '150', reserve: '19',short_detial_info:'中性蠟筆' , detial_info: '中性蠟筆，不易弄髒圖紙，尺寸  5*3 cm'},
    { title: '畫冊', id: '5', price: '100', reserve: '23',short_detial_info:'', detial_info: '真實羊皮紙，尺寸 29*21 cm' },
    { title: '自動筆', id: '6', price: '220', reserve: '4',short_detial_info:'', detial_info: '經典復刻羽毛筆，長 20 cm' },
    { title: '繪圖筆', id: '7', price: '230', reserve: 'max',short_detial_info:'', detial_info: '超黏屑橡皮擦，尺寸  5*3 cm' },
    { title: '色筆', id: '8', price: '150', reserve: '19',short_detial_info:'' , detial_info: '中性蠟筆，不易弄髒圖紙，尺寸  5*3 cm'},
    { title: '雙頭油性奇異筆', id: '9', price: '100', reserve: '23',short_detial_info:'', detial_info: '真實羊皮紙，尺寸 29*21 cm' },
    { title: '2B考試用免削鉛筆', id: '10', price: '220', reserve: '4',short_detial_info:'', detial_info: '經典復刻羽毛筆，長 20 cm' },
    { title: 'SDI手牌 0222B 51mm黑色長尾夾 1打/中盒', id: '11', price: '230',short_detial_info:'', reserve: '0', detial_info: '超黏屑橡皮擦，尺寸  5*3 cm' },
    { title: '成功附擦細字白板筆 1302 1.0mm', id: '12', price: '150', reserve: '19',short_detial_info:'' , detial_info: '中性蠟筆，不易弄髒圖紙，尺寸  5*3 cm'},
  ]);
}, []); // 依赖数组为空，表示只在组件挂载时运行一次

  // 當加入購物車時，更新購物車狀態
  const addToCart = (item) => {
    setCartItems((prevItems) => [...prevItems, item]);
  };

  return(
    <div className='drip_container'>
      <TopBar cartItems={cartItems} os={os()} cartActive={cartActive} products={products}/> {/* 將 cartItems 傳給 TopBar */}
      <DesignPattern />
      <ProductBox addToCart={addToCart} setCartActive={setCartActive} products={products}/> {/* 傳入 setCartActive */}
      <BottomBar />
      <SmilePayBottomBar />
    </div>
  )
}

export default Main