import React from 'react';
import '../style/fail.css';

function FailMark(){
    return(

        <div className="fail_container">
            <div className="fail_circle_border">
                <div className="fail_circle">
                    <div className="fail_error" />
                </div>
            </div>
        </div>

    );
}

export default FailMark;
