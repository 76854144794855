import React, { useState } from 'react'; 
import '../style/Checkout.css';
import Logistics from './Logistics';
import ShrinkBox from './ShrinkBox';
import CashFlow from './CashFlow';

function Checkout( {os} ) {
  const [chosenLogistics, setChosenLogistics] = useState(null); // 保存選中的物流方式
  const [chosenCashFlow, setChosenCashFlow] = useState(null); // 保存選中的物流方式
  const [isShrinkBoxActive, setIsShrinkBoxActive] = useState(false); // 控制 ShrinkBox 是否被點擊的狀態
  const [isAnimating, setIsAnimating] = useState(false);
  const shouldShowLittleMark = Array.isArray(os) && (os.includes('Android') || os.includes('Phone'));
  // 定義函數來處理物流選擇
  const logisticsChoose = (logisticsType) => {
    setChosenLogistics(logisticsType); // 更新選中的物流方式
    shouldShowLittleMark && ( setIsShrinkBoxActive((prev) => !prev))
     // 切換 ShrinkBox 的 active 狀態
  };

    // 定義函數來處理金流選擇
    const cashFlowChoose = (cashFlowType) => {
      setChosenCashFlow(cashFlowType); // 更新選中的物流方式
      //shouldShowLittleMark && ( setIsShrinkBoxActive((prev) => !prev))
       // 切換 ShrinkBox 的 active 狀態
    };
  
    // 定義函數處理 ShrinkBox 點擊
    const handleShrinkBoxClick = () => {
      setIsShrinkBoxActive((prev) => !prev); // 切換 ShrinkBox 的 active 狀態
    };

  return (
    <div className='checkout_box fadeIn'>
      <div>
        <div className='checkout_title'>
          <div className='checkout_title_topBar'>
            <div className='checkout_title_txt'>寄送方式</div>
        
            {shouldShowLittleMark && (
              <ShrinkBox isActive={isShrinkBoxActive} onClick={handleShrinkBoxClick} />
            )}
        </div>

            <div className={`checkoutChooseBox_phone ${isShrinkBoxActive ? 'checkoutChooseBox_show' : ''}`}>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'family' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('family')}
            >
              全家純取貨
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'familyToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('familyToPay')}
            >
              全家取貨付款
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === '7_11' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('7_11')}
            >
              7-11純取貨
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === '7_11ToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('7_11ToPay')}
            >
              7-11取貨付款
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'Tcat' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('Tcat')}
            >
              黑貓宅急便
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'TcatToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('TcatToPay')}
            >
              黑貓貨到付款
            </div>
          </div>
        </div>
        
    
        <div className='checkoutChooseBoxArea'>
          <div className='checkoutChooseBox'>
            <div 
              className={`checkoutChooseItem ${chosenLogistics === 'family' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('family')}
            >
              全家純取貨
            </div>
            <div 
              className={`checkoutChooseItem ${chosenLogistics === 'familyToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('familyToPay')}
            >
              全家取貨付款
            </div>
            <div 
              className={`checkoutChooseItem ${chosenLogistics === '7_11' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('7_11')}
            >
              7-11純取貨
            </div>
            <div 
              className={`checkoutChooseItem ${chosenLogistics === '7_11ToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('7_11ToPay')}
            >
              7-11取貨付款
            </div>
            <div 
              className={`checkoutChooseItem ${chosenLogistics === 'Tcat' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('Tcat')}
            >
              黑貓宅急便
            </div>
            <div 
              className={`checkoutChooseItem ${chosenLogistics === 'TcatToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('TcatToPay')}
            >
              黑貓貨到付款
            </div>
          </div>

            <Logistics logisticsType={chosenLogistics} />
          
        </div>
      </div>

      <div>
        <div className='checkout_title'>
          <div className='checkout_title_topBar'>
            <div className='checkout_title_txt'>付款方式</div>
        
            {shouldShowLittleMark && (
              <ShrinkBox isActive={isShrinkBoxActive} onClick={handleShrinkBoxClick} />
            )}
        </div>

            <div className={`checkoutChooseBox_phone ${isShrinkBoxActive ? 'checkoutChooseBox_show' : ''}`}>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'family' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('family')}
            >
              信用卡
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'familyToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('familyToPay')}
            >
              超商條碼繳費
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === '7_11' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('7_11')}
            >
              全家取貨付款
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === '7_11ToPay' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('7_11ToPay')}
            >
              7-11取貨付款
            </div>
            <div 
              className={`checkoutChooseItem_phone ${chosenLogistics === 'Tcat' ? 'active' : ''}`} 
              onClick={() => logisticsChoose('Tcat')}
            >
              黑貓貨到付款
            </div>

          </div>
        </div>
        
    
        <div className='checkoutChooseBoxArea'>
          <div className='checkoutChooseBox'>
            <div 
              className={`checkoutChooseItem ${chosenCashFlow === 'creditCard' ? 'active' : ''}`} 
              onClick={() => cashFlowChoose('creditCard')}
            >
              信用卡
            </div>
            <div 
              className={`checkoutChooseItem ${chosenCashFlow === 'marketBarcode' ? 'active' : ''}`} 
              onClick={() => cashFlowChoose('marketBarcode')}
            >
              超商條碼繳費
            </div>
            <div 
              className={`checkoutChooseItem ${chosenCashFlow === 'family' ? 'active' : ''}`} 
              onClick={() => cashFlowChoose('family')}
            >
              全家取貨付款
            </div>
            <div 
              className={`checkoutChooseItem ${chosenCashFlow === '7_11' ? 'active' : ''}`} 
              onClick={() => cashFlowChoose('7_11')}
            >
              7-11取貨付款
            </div>
            <div 
              className={`checkoutChooseItem ${chosenCashFlow  === 'Tcat' ? 'active' : ''}`} 
              onClick={() => cashFlowChoose('Tcat')}
            >
              黑貓貨到付款
            </div>
          </div>
    
            <CashFlow cashFlowType={chosenCashFlow} />
      
        </div>
      </div>
        <div className='finalButton'>
          送出訂單
        </div>
    </div>
  );
}

export default Checkout;

