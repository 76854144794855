import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CrossMark from './CrossMark'; // X 按鈕
import Mask from './Mask';  // 背景遮罩
import AlertMessage from './AlertMessage';  // 引入 Mask 組件
import '../style/ProductDetail.css';

const ProductDetail = ({ product, itemIndex, onClose, handleAddToCartFromDetail, cartProductNum, isInsufficientQuantity}) => {
  const [chooseNum, setChooseNum] = useState(1); // 追蹤選擇的商品數量
  const [isActive, setIsActive] = useState(false); // 用來追蹤 active 狀態
  
  const [alertMessage, setAlertMessage] = useState('');
    const [triggerKey, setTriggerKey] = useState(0);
    const [executionSignal, setExecutionSignal] = useState('');

    if (!product) {
      return null;
    }

  

  const handleCloseClick = () => {
    setIsActive(true); // 將 active 狀態設為 true
    setTimeout(() => {
      onClose(); // 添加過渡效果後，再執行 onClose (關閉)
    }, 300); // 假設過渡效果為 300ms
  };

  const decreaseQuantity = () => {
    if (chooseNum > 1) {
      setChooseNum(chooseNum - 1);
    }
  };

  const increaseQuantity = () => {

    console.log('庫存：'+Number(product.reserve))
    console.log('原本已選擇數量：'+chooseNum)
    console.log('購物車內已選擇數量：'+cartProductNum)
    console.log(product)

    //購物車內已選擇數量+購物車內已選擇數量+1 <= 庫存
      if (cartProductNum+chooseNum+1 <= Number(product.reserve)) {
        setChooseNum(chooseNum + 1);
      }else{
        setAlertMessage(`${product.title} 以達到購買上限`);
        setExecutionSignal('fail');
        setTriggerKey(prev => prev + 1); // 觸發提示
      }
  };

  const handleAddToCartClick = () => {
    handleAddToCartFromDetail(product, chooseNum, itemIndex); // 根據選擇的數量調用父組件的添加函數
    handleCloseClick(); // 加入購物車後關閉詳情
  };

  return ReactDOM.createPortal(
    <div className={`product_detail_container ${isActive ? 'detail_back' : ''}`}>
      <CrossMark handleClose={handleCloseClick} />
      <div className='detial_area_1'>
        <div className='detial_img_container'>
          {product.reserve == '0' && (<div className='soldOutMark'>已售完</div>)}
          <img src={`/user/15044/${product.id}.jpg`} alt={product.title} />
        </div>
        <div className='detial_short_info_container'>
          <h2>{product.title}</h2>
          <p>{product.short_detial_info}</p>
          <hr className='detial_hr_style_1'></hr>
          <p>NT$ {product.price}</p>
      
     
              {isInsufficientQuantity ? (
                // 如果 isInsufficientQuantity 是true
                <div className='text-danger'>數量不足</div>
              ) : (
                  // 如果 isInsufficientQuantity 是 false
                <>
                <div className="drip_cart_number_container">
                  <div onClick={decreaseQuantity}>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Edit / Remove_Minus">
                        <path d="M6 12H18" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </div>

                  <input type="text" className="form-control text-center" value={chooseNum} readOnly />

                  <div onClick={increaseQuantity}>
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Edit / Add_Plus">
                        <path d="M6 12H12M12 12H18M12 12V18M12 12V6" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </div>
                </div>
                 <button className="btn btn-primary" onClick={handleAddToCartClick}>加入購物車</button>
                 </>
              )}         
        </div>
      </div>
      <hr className='detial_hr_style_2'></hr>
      <div className='detial_area_2'>
        <h3>商品描述</h3>
            <p>商品數量： {product.reserve}</p>
        <div>{product.detial_info}</div>
      </div>
      <Mask type={isActive} />
      <AlertMessage message={alertMessage} triggerKey={triggerKey} executionSignal={executionSignal} />
    </div>,
    document.body
  );
};

export default ProductDetail;
