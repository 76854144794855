import React from 'react';
import ReactDOM from 'react-dom';

function Mask({type}){
if(type){
  document.body.style.overflow = '';  // 恢復頁面滾動
}else{
  document.body.style.overflow = 'hidden';  // 恢復頁面滾動
}

  console.log(type)
    return ReactDOM.createPortal(
        <div className={`drip_mask fadeIn ${type ? 'fadeOut' : ''}`}/>,
        document.body  // 指定渲染到 document.body
      );
}

export default Mask;
