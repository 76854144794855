import React from 'react';

function BottomBar(){
    const personal_info = [
        {storeName: "Smilepay",titleSlogan: "pay with smile"},
    ]
    return(
        <nav className="w-auto bg-light mw-100 border-top border-secondary p-3 mt-3 d-flex flex-column align-items-center">
            <div className="d-flex flex-column bd-highlight mb-3">
                <h2>✨Drip文具店</h2>
                <h6>品質保證</h6>
                <h6>售後服務</h6>
                <h6>精緻笑容</h6>
                <h6>熱情招待</h6>
                <h2>📮聯絡我們</h2>
                <h6>電話：037-376006</h6>
                <h6>email：service@smilepay.net</h6>
            </div>
      </nav>
        
    )
}
export default BottomBar;